import $axios from './index'
// 问题
//问答：问题分类列表
export function wd_typelist(data){
	const url = '/api/app/v1/wd/type/list';
	return $axios.post(url,data);
}
//问答：推荐列表
export function wd_recommendlist(data){
	const url = '/api/app/v1/wd/recommend/list';
	return $axios.post(url,data);
}
//问答推荐/热门列表
export function hotWd_list(data){
	const url = '/api/pc/v1/wd/list';
	return $axios.post(url,data);
}
//问答：问答列表详情
export function wd_detail(data){
	const url = '/api/app/v1/wd/detail/list';
	return $axios.post(url,data);
}
//问答：收藏或取消收藏
export function wd_collect(data){
	const url = '/api/app/v1/wd/collect';
	return $axios.post(url,data);
}
//问答：追问
export function closely_question(data){
	const url = '/api/app/v1/wd/closely/question';
	return $axios.post(url,data);
}
//问答：轮播图列表
export function wd_bannerlist(data){
	const url = '/api/app/v1/wd/banner/list';
	return $axios.post(url,data);
}

//问答：发布问题
export function publish_question(data){
	const url = '/api/app/v1/wd/publish/question';
	return $axios.post(url,data);
}

//问答会员支付
export function member_pay(data){
	const url = '/api/pc/v1/pay/wd/member/pay';
	return $axios.post(url,data);
}
//问答：查询会员项
export function wd_member(data){
	const url = '/api/app/v1/wd/member/item/list';
	return $axios.post(url,data);
}

//问答：最新列表
export function wd_latest(data){
	const url = '/api/app/v1/wd/latest/list';
	return $axios.post(url,data);
}

//问答：专家列表
export function wd_expertlist(data){
	const url = '/api/app/v1/wd/expert/list';
	return $axios.post(url,data);
}

//问答：专家回答列表
export function wd_replylist(data){
	const url = '/api/app/v1/wd/expert/reply/list';
	return $axios.post(url,data);
}

//课程安排（章节）
export function course_chapters(data){
	const url = '/api/app/v1/course/study/coursepackage/course/chapters';
	return $axios.post(url,data);
}

//课程课时答疑列表
export function coursepackage_dayiList(data){
	const url = '/api/app/v1/course/study/coursepackage/course/dayi/list';
	return $axios.post(url,data);
}
//课程课时答疑添加
export function dayi_add(data){
	const url = '/api/app/v1/course/study/coursepackage/course/dayi/add';
	return $axios.post(url,data);
}
//课程讲义列表
export function coursepackage_courseware(data){
	const url = '/api/app/v1/course/study/coursepackage/course/courseware';
	return $axios.post(url,data);
}
//课程课时笔记
export function classhour_noteList(data){
	const url = '/api/app/v1/course/study/coursepackage/course/classhour/note/list';
	return $axios.post(url,data);
}

//获取视频加密凭证
export function common_playerSafe(data){
	const url = '/api/pc/v1/common/playerSafe';
	return $axios.post(url,data);
}
//添加课程课时笔记
export function note_add(data){
	const url = '/api/app/v1/course/study/coursepackage/course/classhour/note/add';
	return $axios.post(url,data);
}
//修改课程课时笔记
export function note_edit(data){
	const url = '/api/app/v1/course/study/coursepackage/course/classhour/note/edit';
	return $axios.post(url,data);
}


//资料一级分类
export function material_firstType(data){
	const url = '/api/pc/v1/material/first/type/list';
	return $axios.post(url,data);
}

//资料二级分类
export function material_secondType(data){
	const url = '/api/pc/v1/material/second/type/list';
	return $axios.post(url,data);
}

//资料列表
export function material_list(data){
	const url = '/api/pc/v1/material/list';
	return $axios.post(url,data);
}
//相关资料
export function related_list(data){
	const url = '/api/pc/v1/material/related/list';
	return $axios.post(url,data);
}
//增加下载量
export function add_download(data){
	const url = '/api/pc/v1/material/add/download/count';
	return $axios.post(url,data);
}


//资讯一级分类列表
export function information_firstType(data){
	const url = '/api/pc/v1/information/first/type/list';
	return $axios.post(url,data);
}

//资讯列表
export function information_list(data){
	const url = '/api/pc/v1/information/list';
	return $axios.post(url,data);
}

//详情
export function information_detail(data){
	const url = '/api/pc/v1/information/detail';
	return $axios.post(url,data);
}

// 地址
//添加收货地址
export function address_add(data){
	const url = '/api/app/v1/order/address/add';
	return $axios.post(url,data);
}
//删除收货地址
export function address_delete(data){
	const url = '/api/app/v1/order/address/delete';
	return $axios.post(url,data);
}
//修改收货地址
export function address_edit(data){
	const url = '/api/app/v1/order/address/edit';
	return $axios.post(url,data);
}
//我的收货地址列表
export function address_list(data){
	const url = '/api/app/v1/order/address/list';
	return $axios.post(url,data);
}
//设置默认地址
export function address_setDefault(data){
	const url = '/api/app/v1/order/address/set/default';
	return $axios.post(url,data);
}
//获取地区，通过城市ID
export function get_areas(data){
	const url = '/api/frame/v1/area/areas';
	return $axios.post(url,data);
}
//获取城市，通过省份ID
export function get_citys(data){
	const url = '/api/frame/v1/area/citys';
	return $axios.post(url,data);
}
//获取所有省份
export function get_provices(data){
	const url = '/api/frame/v1/area/provices';
	return $axios.post(url,data);
}


// 老师
//专家列表
export function teacher_list(data){
	const url = '/api/pc/v1/index/teacher/list';
	return $axios.post(url,data);
}
//授课老师的课程套餐列表
export function teacher_courseList(data){
	const url = '/api/pc/v1/course/package/select/by/teacher/id/list';
	return $axios.post(url,data);
}
//课程套餐搜索
export function course_search(data){
	const url = '/api/app/v1/index/course/search/list';
	return $axios.post(url,data);
}
//问答搜索
export function wd_search(data){
	const url = '/api/app/v1/index/wd/search/list';
	return $axios.post(url,data);
}

//视频进度上报
export function planReportedData(data){
	const url = '/api/app/v1/course/package/receive/course/planReportedData';
	return $axios.post(url,data);
}

//回放视频上报
export function playbackReportedData(data){
	const url = '/api/app/v1/course/package/playback/course/playbackReportedData';
	return $axios.post(url,data);
}

//套餐搜索
export function taocan_search(data){
	const url = '/api/app/v1/course/package/list';
	return $axios.post(url,data);
}
//问答搜索
export function wenda_search(data){
	const url = '/api/app/v1/wd/latest/list';
	return $axios.post(url,data);
}
//资讯搜索
export function zixun_search(data){
	const url = '/api/pc/v1/information/list';
	return $axios.post(url,data);
}
//资料搜索
export function ziliao_search(data){
	const url = '/api/pc/v1/material/list';
	return $axios.post(url,data);
}
//老师类别
export function teacher_leibie(data){
	const url = '/api/pc/v1/index/teacher/category/list';
	return $axios.post(url,data);
}
//获取学习课时状态
export function panduanjindu(data){
	const url = '/api/pc/v1/course/package/get/study/class/hour/status';
	return $axios.post(url,data);
}
//观看直播
export function watchLive(data){
	const url = '/api/app/v1/live/watchLive';
	return $axios.post(url,data);
}
// 
export function agreement(data){
	const url = '/api/app/v1/h5/agreement';
	return $axios.get(url,data);
}