<template>
    <div class="vipPayPage">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="vipPayPage_header">
            <img src="@/assets/ask/vipPage_banner.png" alt="">
        </div>
        <div class="vipPayPage_main">
            <div class="vipPayPage_main_header">
                <span>选择你的会员计划</span>
            </div>
            <div class="vipPayPage_main_setMeal">
                <div class="vipPayPage_main_setMeal_item">
                    <div class="vipPayPage_main_setMeal_item_top">
                        <span>1个月尊享会员</span>
                        <img src="@/assets/ask/vip1_banner.png" alt="">
                    </div>
                    <div class="vipPayPage_main_setMeal_item_bottom">
                        <p class="vipPayPage_main_setMeal_item_bottom_price">
                            <span>¥</span>
                            <span>{{vipList[0].price}}</span>
                            <span>元</span>
                        </p>
                        <p class="vipPayPage_main_setMeal_item_bottom_btn">
                            <el-button type="primary" @click="goVipOrder(vipList[0],1)">立即购买</el-button>
                        </p>
                    </div>
                </div>
                <div class="vipPayPage_main_setMeal_item">
                    <div class="vipPayPage_main_setMeal_item_top">
                        <span>3个月尊享会员(推荐)</span>
                        <img src="@/assets/ask/vip2_banner.png" alt="">
                        <img src="@/assets/ask/recommend_tips.png" alt="" class="tips">
                    </div>
                    <div class="vipPayPage_main_setMeal_item_bottom">
                        <p class="vipPayPage_main_setMeal_item_bottom_price">
                            <span>¥</span>
                            <span>{{vipList[1].price}}</span>
                            <span>元</span>
                        </p>
                        <p class="vipPayPage_main_setMeal_item_bottom_explain">低至{{price_temp_3}}元/月</p>
                        <p class="vipPayPage_main_setMeal_item_bottom_btn">
                            <el-button type="primary" @click="goVipOrder(vipList[1],2)">立即购买</el-button>
                        </p>
                    </div>
                </div>
                <div class="vipPayPage_main_setMeal_item">
                    <div class="vipPayPage_main_setMeal_item_top">
                        <span>12个月尊享会员</span>
                        <img src="@/assets/ask/vip3_banner.png" alt="">
                    </div>
                    <div class="vipPayPage_main_setMeal_item_bottom">
                        <p class="vipPayPage_main_setMeal_item_bottom_price">
                            <span>¥</span>
                            <span>{{vipList[2].price}}</span>
                            <span>元</span>
                        </p>
                        <p class="vipPayPage_main_setMeal_item_bottom_explain">低至{{price_temp_12}}元/月</p>    
                        <p class="vipPayPage_main_setMeal_item_bottom_btn">
                            <el-button type="primary" @click="goVipOrder(vipList[2],3)">立即购买</el-button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue';
import t_footer from '@/components/t_footer/index.vue'
import { wd_member,member_pay } from "@/api/other";
export default {
    name:'vipPayPage',
    components: {
        t_header,
        t_footer,
    },
    data(){
        return{
            vipList:[],
            isUserInfo:false,
            price_temp_3:0,
            price_temp_12:0,
        }
    },
    mounted(){
        this.wd_member()
    },
    methods:{
        // 父传子
        getTodos(e){
            console.log(e)
            this.isUserInfo=e
        },
        // 查询会员项
        wd_member(){
            wd_member().then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.vipList=res.data.data
                    for(var i=0;i<this.vipList.length;i++){
                        var price = this.vipList[i].price;
                        if(i ==1){
                            this.price_temp_3 = (Number(price)/3).toFixed(2);
                            // console.log((Number(price)/3).toFixed(2));
                        }else if(i ==2){
                            this.price_temp_12 = (Number(price)/12).toFixed(2);
                            // console.log(Number(price)/12);
                        }
                    }
                    // for(){

                    // }
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 去订单
        goVipOrder(item,num){
            var title=''
            if(num==1){
                title='1个月尊享会员'
            }else if(num==2){
                title='3个月尊享会员'
            }else{
                title='12个月尊享会员'
            }
            let routeUrl =this.$router.resolve({
                path:'/vipOrder',
                query:{
                    item:JSON.stringify(item),
                    num:num,
                    title:title
                }
            })
            window.open(routeUrl.href, '_blank');
        }
    }
}
</script>

<style>

</style>